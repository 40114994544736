<template>
  <v-app class="transparent">
    <v-container
      id="regular-tables"
      fluid
      tag="section"
    >
      <base-v-component
        heading="Duration List"
      />

      <base-material-card
        icon="mdi-clipboard-text"
        title="Duration List"
        class="px-5 py-3"
      >
        <v-simple-table>
          <filter-list
            :filters="filter"
            :on-change="getDurations">
          </filter-list>
          <tbody>
          <tr v-for="duration in durations">
            <td>{{ duration.id }}</td>
            <td>{{ duration.duration | dateDuration}}</td>
            <td>{{ duration.is_active | isActive }}</td>
            <td>{{ duration.created_at }}</td>
            <td>{{ duration.updated_at }}</td>
          </tr>
          </tbody>
        </v-simple-table>
      </base-material-card>
      <div class="text-center">
        <v-pagination
          v-if="pagination.total > 10"
          v-model="query.page"
          :length="pagination.length"
          :total-visible="7"
          @input="getDurations"
          @next="getDurations"
          @previous="getDurations"
        ></v-pagination>
      </div>
      <div class="py-3"/>
    </v-container>
  </v-app>
</template>


<script>
  import FilterList from "../../components/base/FilterList";
  export default {
    name: "DurationList",
    components: {FilterList},
    created() {
      this.getUrlParams();
      this.getDurations();
    },
    watch: {
      $route() {
        this.getUrlParams();
        this.getDurations();
      }
    },
    data() {
      return {
        durations: [],
        pagination: {
          page: 1,
          length: 0,
          total: 0
        },
        query: {
          page: 1
        },
        filter: [
          {
            name: "page",
            value: 1,
            type: "pagination"
          },
          {
            name: "id",
            value: "",
            type: "text_field"
          },
          {
            name: "duration",
            value: "",
            type: "text_field"
          },
          {
            name: "is_active",
            value: "",
            type: "select",
            items: [
              {text: "Active", value: 1},
              {text: "Inactive", value: 0},
            ]
          },
          {
            name: "created_at",
            value: "",
            type: "text_field"
          },
          {
            name: "updated_at",
            value: "",
            type: "text_field"
          },
        ]
      }
    },
    methods: {
      getDurations() {
        this.$authApi.get(`/durations`, {
          params: this.query
        }).then(res => {
          this.durations = res.data.data.data;
          this.pagination.length = res.data.data.last_page;
          this.pagination.total = res.data.data.total;
        })
      },
      getUrlParams() {
        if (!Object.keys(this.$route.query).length) {
          this.query = {
            page: 1
          };
          this.filter = [
            {
              name: "page",
              value: 1,
              type: "pagination"
            },
            {
              name: "id",
              value: "",
              type: "text_field"
            },
            {
              name: "duration",
              value: "",
              type: "text_field"
            },
            {
              name: "is_active",
              value: "",
              type: "select",
              items: [
                {text: "Active", value: 1},
                {text: "Inactive", value: 0},
              ]
            },
            {
              name: "created_at",
              value: "",
              type: "text_field"
            },
            {
              name: "updated_at",
              value: "",
              type: "text_field"
            },
          ];
          this.$root.$emit('refreshParams', this.filter)
        } else {
          this.query = this.$route.query;
          this.filter.forEach(param => {
            param.value = this.query[param['name']];
            if (param.items) {
              param.value = parseInt(this.query[param['name']]);
            }
          });
        }
      },
    }
  }
</script>

<style scoped>

</style>
